import React from "react";
import Dashboard from "./pages/Dashboard";
import Visionnages from "./pages/Visionnages";
import Partenaires from "./pages/Partenaires";
import Utilisateurs from "./pages/Utilisateurs";
import Donnees from "./pages/Donnees";
import Navbar from "./pages/Navbar";
import { Route, Routes } from "react-router-dom";
import Login from "./Login";
import Settings from "./pages/Settings";
import PartReg from "./pages/PartReg";
import PartRegForm from "./pages/PartRegForm";
import PrivateRoute from "./private";
import LoginRedirect from "./private/LoginRedirect";
import AdminReset from "./pages/AdminReset";
import Points from "./pages/Points";

export default function Acceuil() {
  return (
    <>
      {/* <Login/> */}
      <Navbar />
      <section className="accueil_container">
        <Routes>
          <Route
            path="/"
            element={
              <LoginRedirect>
                <Login />
              </LoginRedirect>
            }
          />
          <Route
            path="/home"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/visionnages"
            element={
              <PrivateRoute>
                <Visionnages />
              </PrivateRoute>
            }
          />
          <Route
            path="/partenaires"
            element={
              <PrivateRoute>
                <Partenaires />
              </PrivateRoute>
            }
          />
          <Route
            path="/utilisateurs"
            element={
              <PrivateRoute>
                <Utilisateurs />
              </PrivateRoute>
            }
          />
          <Route
            path="/donnees"
            element={
              <PrivateRoute>
                <Donnees />
              </PrivateRoute>
            }
          />
          <Route
            path="/points"
            element={
              <PrivateRoute>
                <Points />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route path="/partenaires/reg" element={<PartReg />} />
          <Route path="/partenaires/regform" element={<PartRegForm />} />
          <Route path="/adminReset" element={<AdminReset />} />
        </Routes>
      </section>
    </>
  );
}
