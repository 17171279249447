import React, { useState, useEffect } from "react";
import partner_logo from "./images/yango_logo.png";
import partner_logo2 from "./images/djamo_logo.png";
import partner_logo3 from "./images/eco_logo.jpg";
import axios from "axios";
import {ScaleLoader} from "react-spinners";
const { BACKEND_API_URL } = require("../../../../env");


const Partner = (props) => {

  return (
    <div className="sect2_partner">
      <div>
        <b>{props.partner}</b>
        <b>
          {props.visionnages} <i className="fa-solid fa-eye"></i>{" "}
        </b>
      </div>
      <div>
        <img className="partner_logo" src={props.logo} alt="" />
      </div>
    </div>
  );
};

const Sect2 = () => {

  const [partenaires, setPartenaires] = useState([]);
  const [loading, setLoading] = useState(false);
  const limit = 6;
  useEffect(() => {
    const page = 1;
    const searchTerm = "";
    setLoading(true);
    axios
      // .get("https://baclgigaplus.herokuapp.com/api/partenaires")
      .get(`${BACKEND_API_URL}/api/partenaires?limit=${limit}&page=${page}&search=${searchTerm}`)
      .then((response) => {

        // const filteredPartenaires = response.data.filter(partenaire => partenaire.valide === true);
        // const partenaires = response.data.partenaires;
        
        
        // const count = partenaires.length;
        setPartenaires(response.data.partenaires);
        // console.log("filteredPartenaires", response.data.partenaires)
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false);
      });
  }, []);


  return (
    <div className="sect2">
      {/* 
        <Partner partner="Eco Eburnie" visionnages="174" logo={partner_logo3} />
        <Partner partner="KDS" visionnages="120" logo={partner_logo}/>
        <Partner partner="Yango" visionnages="530" logo={partner_logo2}/>
        <Partner partner="Youzou" visionnages="310" logo={partner_logo3}/>
        <Partner partner="Djamo" visionnages="352" logo={partner_logo}/>
        <Partner partner="Djamo" visionnages="352" logo={partner_logo}/> */}

{loading && (

<ScaleLoader style={{
        position: 'absolute',
        top: '7vh',
        left: '27%',      
}}  color="#4EAC04" />

      )}
      

      {partenaires.slice(0,6).map((partenaire, index) => {

        const img = partenaire.logo.data.data
const base64String = btoa(String.fromCharCode(...new Uint8Array(img)));

      return <Partner key={partenaire._id} partner={
        partenaire.nomPartenaire.length > 7? partenaire.nomPartenaire.substring(0, 7) + "..." : partenaire.nomPartenaire
      } visionnages={partenaire.visionnages.length} logo={`data:image/png;base64,${base64String}`} />
      
})}

<a href="/partenaires" className="btn_see_more">Voir plus</a>

{/* <button className="btn_see_more">Voir plus</button> */}

    </div>
  );
};

export default Sect2;
