import React from 'react'
import logo from "./images/giga_plus_logo.png"
import {Link, useMatch, useResolvedPath, useNavigate} from "react-router-dom"
// import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Cookies, useCookies } from "react-cookie";


const Navbar = () => {

  const settingsResovledPath = useResolvedPath("/settings")
  const isSettingsActive = useMatch({path : settingsResovledPath.pathname, end:true})
  const navigate = useNavigate()
  const cookies = new Cookies();

  const handleLogout = (e) => { 
    e.preventDefault();

    // setAdmin(null);
    // localStorage.removeItem("giga_admin_jwtToken");
    // localStorage.removeItem("giga_admin");
    cookies.remove("giga_admin");
    navigate("/");
    

  }
  return (
    <nav className="nav" >
        <Link to="/home"  > <img className='giga_plus_logo' src={logo} alt="" /> </Link>
        <ul className='nav_li' >
            {/* <li> <Link className='menu_link' to='/' ></Link> </li>
            <li> <Link className='menu_link' to='/visionnages' >Visionnages</Link> </li>
            <li> <Link className='menu_link' to='/partenaires' >Partenaires</Link> </li>
            <li> <Link className='menu_link' to='/utilisateurs' >Utilisateurs</Link> </li>
            <li> <Link className='menu_link' to='/donnees' >Données</Link> </li> */}
            <CustomLink to="/home" > Tableau de bord </CustomLink>
            <CustomLink to="/visionnages" > Visionnages </CustomLink>
            <CustomLink to="/partenaires" > Partenaires </CustomLink>
            <CustomLink to="/utilisateurs" > Utilisateurs </CustomLink>
            <CustomLink to="/points" > Gestionnaire </CustomLink>
            {/* <CustomLink to="/donnees" > Parrainage </CustomLink> */}
        </ul>

        <ul className='nav_icons' >
            <li style={{display:"none"}} > <div className='nav_icon'> <i className='fa-solid fa-circle-user ' ></i></div>  </li>
            <li>
            <Tooltip title="Paramètres">
               <div className= {isSettingsActive ? "setings_icon_active nav_icon" : "nav_icon"} > <Link to="/settings" >  <i className='fa-solid fa-gear ' ></i> </Link> </div> 
            </Tooltip>  
             </li>
            <li>
            <Tooltip title="Déconnexion">
               <div onClick={handleLogout} className='nav_icon'> <a href="#" > <i className='fa-solid fa-power-off' ></i> </a> </div> 
            </Tooltip>
            </li>
        </ul>

    </nav>
  )
}

function CustomLink ({to, children, ...props}) {
  const resovledPath = useResolvedPath(to)
  const isActive = useMatch({path : resovledPath.pathname, end:true})
  return (
    <li  >
      <Link className={isActive ? "menu_link_active" : "menu_link"} to={to} {...props} >
        {children}
      </Link>
    </li>
  )
}

export default Navbar
