import React from 'react'
import Sect1 from './sub/partenaires/Sect1'
import Sect2 from './sub/partenaires/Sect2'

const Partenaires = () => {
  return (
    <section className='partenaires' >

     <Sect1 />
     <Sect2 />

    </section>
  )
}

export default Partenaires