import React, { useState } from "react";
import giga_plus from "./pages/images/giga_plus_logo.png";
import login_bg from "./pages/images/web_login_page_bg.png";
import axios from "axios";
import { json, Navigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import loader from "./app_loader.gif";
import loader2 from "./loader2.gif";
import { Cookies, useCookies } from "react-cookie";
// require('dotenv').config()



const { BACKEND_API_URL } = require("../env");
// alert(BACKEND_API_URL);
const Login = () => {
  const [admin, setAdmin] = useState(null);
  const [emailAdmin, setEmailAdmin] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();


  const handleLogin = async (e) => {
    e.preventDefault();

    if (!validator.isEmail(emailAdmin)) {
      setOpen(true);
      setError("Email non conforme!");
      return;
    }

    setLoading(true);

    try {
      const res = await axios.post(`${BACKEND_API_URL}/api/adminLogin`, {
        emailAdmin,
        password,
      });
      setAdmin(res.data);
      // // console.log(res.data);
      // localStorage.setItem(
      //   "giga_admin_jwtToken",
      //   JSON.stringify(res.data.accessToken)
      // );
      // localStorage.setItem("giga_admin", JSON.stringify(res.data));

      cookies.set('giga_admin_jwtToken', res.data.accessToken);
      cookies.set('giga_admin', res.data);

      // console.log(cookies.get('giga_admin_jwtToken'));
      // console.log(cookies.get('giga_admin'));

      navigate("/home");
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpen(true);
      setError("Email ou mot de pass incorrecte!");
    }

    setLoading(false);
  };

  return (
    <div style={{backgroundColor:"rgb(27, 30, 37)" }} className="login_page">
      <div className="login_page_main">
        <div>
          <img className="login_form_main_logo" src={giga_plus} alt="" />
        </div>
        <div>
          <div className="login_form_field">
            {open && <Alert severity="error"> {error} </Alert>}

            {loading && (
              <img className="login_loader" src={loader2} alt="loading" />
            )}

            <br />
            {/* <label for='nom' >Nom Partenaire</label> */}
            <input
              onChange={(e) => setEmailAdmin(e.target.value)}
              id="emailAdmin"
              type="email"
              placeholder="Entrer votre email"
              name="emailAdmin"
            />
          </div>

          <div className="login_form_field">
            {/* <label for='email' >Email Partenaire</label> */}
            <input
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              type="password"
              placeholder="Entrer votre mot de pass"
              name="password"
            />
          </div>

          <a href="#">
            {" "}
            <button onClick={handleLogin} className="login_page_main_button">
              {" "}
              Connexion{" "}
            </button>{" "}
          </a>

          <div className="login_form_question_div">
            {" "}
            -&nbsp; 
            <a style={{ 
              textDecoration: "none",
              color: "white",
             }} href="/adminReset">
             Mot de pass oublié? </a> &nbsp;-{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
