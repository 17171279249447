import React, { useState, useContext, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import validator from "validator";
import axios from "axios";
import { RegContext } from "../../regContext/RegContext";
const { BACKEND_API_URL } = require("../../../../env");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Details = (props) => {
  // const [displayItem, setDisplayItem] = useState(true)
  const [show, setShow] = useState(false);

  const deleteVideo = () => {
    axios
      .delete(`${BACKEND_API_URL}/api/video/deleteVideo/${props.idVis}`)
      .then((res) => {
        console.log(res);
        setShow(true);
        window.location.reload();
      })
      .catch((e) => console.log(e));
  };
  const date = new Date(props.dateAjout); // Crée une instance de l'objet Date à partir de la prop 'date'

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  const formattedDate = `${date.toLocaleDateString("fr-FR", options)}`;
  const blockVideo = (id) => {
    axios
      .put(`${BACKEND_API_URL}/api/video/video/${id}/block`)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((e) => console.log(e));
    console.log("ok block");
  };
  const unBlockVideo = (id) => {
    axios
      .put(`${BACKEND_API_URL}/api/video/video/${id}/unblock`)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((e) => console.log(e));
    console.log("ok unblock");
  };
  const handleBlockClick = (id) => {
    blockVideo(id);
  };
  const handleUnBlockClick = (id) => {
    unBlockVideo(id);
    // window.location.reload();
  };



  // nomFilleuil={user.usagerFilleuil.nom}
  // nomParrain={user.usagerParrain.nom}
  // telFilleuil={user.usagerFilleuil.tel}
  // telParrain={user.usagerParrain.tel}
  // emailFilleuil={user.usagerFilleuil.email}
  // emailParrain={user.usagerParrain.email}
  // dateAjout={user.dateAjout}
  // onClose={closeChild}

  return (
    <div className="sect3_elem_details">
      <div>
        {/* <FontAwesomeIcon  style={{color:"black"}}  icon="fa-solid fa-circle-xmark" /> */}
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails du parrainage </b>{" "}
              </span>
            
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> Nom filleul : &nbsp; </span> <span> {props.nomFilleuil} </span>{" "}
              </div>

              <div>
                <span> Tel filleul : &nbsp; </span>{" "}
                <span> {props.telFilleuil} </span>{" "}
              </div>

              <div>
                <span> Email filleuil : &nbsp; </span>{" "}
                <span> {props.emailFilleuil} </span>{" "}
              </div>
             
              <div>
                <span> Nom parrain : &nbsp; </span>{" "}
                <span> {props.nomParrain} </span>{" "}
              </div>

              <div>
                <span> Tel parrain : &nbsp; </span>{" "}
                <span> {props.telParrain} </span>{" "}
              </div>
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails du parrainage </b>{" "}
              </span>
              {/* <span> {props.partDescription} </span> */}
            </div>

            <div className="row1_details_details">
              <br />
              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> Email parrain : &nbsp; </span>{" "}
                <span> {props.emailParrain} </span>{" "}
              </div>

              {/* <div>
              <span> Description Activite : &nbsp; </span>{" "}
              <span> {props.partDescription} </span>{" "}
            </div> */}

              <div className="row_status_div">
                <span> Date Ajout: &nbsp; </span>
                <span> {formattedDate} </span>{" "}
              </div>
              {/* <div className="row_status_div">
                <span> Nom utilisateur: &nbsp; </span>
                <span> {props.nomUsager} </span>
              </div> */}
              {/* <div className="row_status_div">
            <span> Statu video: &nbsp; </span> 
            <span> {props.statut} </span>{" "}

            </div> */}
              {/* <div className="row_status_div">
                <span>Choisir le statut:</span>
                <button
                  style={{
                    width: "80px",
                    height: "25px",
                    borderRadius: 7,
                    border: "none",
                    backgroundColor: "red",
                    fontWeight: "bold",
                    color: "#ffff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleBlockClick(props.idVis)}
                >
                  Bloquer
                </button>
                <button
                  style={{
                    width: "90px",
                    height: "25px",
                    borderRadius: 7,
                    border: "none",
                    backgroundColor: "green",
                    fontWeight: "bold",
                    color: "#ffff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleUnBlockClick(props.idVis)}
                >
                  Debloquer
                </button>
              </div> */}
            </div>
            {/* <button
              style={{
                marginTop: 400,
                height: 30,
                width: 100,
                borderRadius: 7,
                border: "none",
                fontWeight: "bold",
                color: "#ffff",
                backgroundColor: "red",
                cursor: "pointer",
              }}
              onClick={() => deleteVideo()}
            >
              Supprimer
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const Sect2 = () => {
  const [nomOperateur, setNomOperateur] = useState([]);
  const [info, setInfo] = useState([]);
  const [displayItem, setDisplayItem] = useState(false);
  const [user, setUser] = useState([]);

  const [total, setTotal] = useState(0);
  const [itemsPerPage2, setItemsPerPage2] = useState(6);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(0);
  const [hommesSandwich, setHommesSandwich] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [visionnages, setVisionnages] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [retraits, setRetraits] = useState([]);
  const [parrainages, setParrainages] = useState([]);

  const closeChild = () => {
    setDisplayItem(false);
  };
  useEffect(() => {
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = currentPage2;
    axios
      .get(
        `${BACKEND_API_URL}/api/parrainage`
      )
      .then((response) => {
        // console.log(response.data.viewings);
        // setVisionnages(response.data.viewings);
        // setTotalPages2(response.data.totalPages);
        // setRetraits(response.data.parrainages);

        console.log("parrainages: ", response.data);

        setParrainages(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleUser = (info) => {
    setUser(info);
    setDisplayItem(true);
  };

  return (
    <div className="partenaires_sect2 points_sect2">
      <div className="gestions_sect2_top">
        <div className="partenaires_sect1_bottom_sect_topPoints partenaires_sect1_bottom_sect_topPoints3 partenaires_sect1_bottom_sect_topPoints4">
          <div>Recap partages</div>
          <div className="search-bar-container2">
            <input
              type="text"
              placeholder="Rechercher un usager par son email"
              className="search-bar-hs2"
              value={searchTerm2}
              onChange={(e) => setSearchTerm2(e.target.value)}
            />
            <button
              onClick=""
              disabled={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? true
                  : false
              }
              className={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? "search-button-disabled2"
                  : "search-button2"
              }
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        <div
          className="partenaires_sect1_bottom_sect_table partenaires_sect1_bottom_sect_table3 "
          //  style={{ overflowY: 'scroll', height: '300px' }}
        >
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Usager parrainé</th>
                <th>Date d'enregistrement</th>
                <th>Nom parrain</th>
                {/* <th>Moyen de paiment</th> */}
                <th> Action </th>
              </tr>
            </thead>
            <tbody>

              {retraits.length === 0 ?(
                <tr>
                  <td colSpan="5" style={{textAlign:"center", 
                  color:"black",
                  fontSize:"20px",
                  fontWeight:"bold"
                  
                  }}> Aucun partage pour l'instant</td>
                </tr>
              
              ):(

                retraits.map((retrait) => (
                  <tr key={retrait._id}>
                    <td>{retrait._id}</td>
                    <td>{retrait.email}</td>
                    <td>{retrait.pointRetrait}</td>
                    <td>{retrait.numPaiement}</td>
                    {/* <td>{retrait.moyenPaiement}</td> */}
                    {/* <td>{retrait.idOperateur.nomOperateur}</td> */}
  
                    {/* <td>1</td>
                <td>58</td>
                <td>88</td>
                <td>186</td>
                <td>05</td>
                <td>05</td> */}
                    <td>
                      {" "}
                      <i
                        onClick={() => handleUser(retrait)}
                        className="fa-solid fa-tv"
                      ></i>
                    </td>
                  </tr>
                ))

              )
              }

             
            </tbody>
          </table>
          {/* {displayItem && (
            <Details
              id={user._id}
              nom={user.idUsager.nom}
              video={user.idVideo.intitulee}
              tel={user.idUsager.tel}
              gigaDonnée={user.nbreDonneeCreditee}
              datenaisse={user.idUsager.datenaissance}
              operateur={user.idOperateur.nomOperateur}
              onClose={closeChild}
            />
          )} */}
        </div>
      </div>

      <div className="gestions_sect2_bottom">
        <div className="partenaires_sect1_bottom_sect_topPoints partenaires_sect1_bottom_sect_topPoints3 partenaires_sect1_bottom_sect_topPoints4">
          <div>Recap parrainages</div>
          <div className="search-bar-container2">
            <input
              type="text"
              placeholder="Rechercher un usager parrainé"
              className="search-bar-hs2"
              value={searchTerm2}
              onChange={(e) => setSearchTerm2(e.target.value)}
            />
            <button
              onClick=""
              disabled={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? true
                  : false
              }
              className={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? "search-button-disabled2"
                  : "search-button2"
              }
            >
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>

        <div
          className="partenaires_sect1_bottom_sect_table partenaires_sect1_bottom_sect_table3 "
          //  style={{ overflowY: 'scroll', height: '300px' }}
        >
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Usager parrainé</th>
                <th>Date d'enregistrement</th>
                <th>Nom parrain</th>
                {/* <th>Moyen de paiment</th> */}
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {parrainages.map((parrainage) => (
                <tr key={parrainage._id}>
                  <td>{parrainage._id}</td>
                  <td>{parrainage.usagerFilleuil.nom}</td>
                  <td>  {
                      new Date(parrainage.dateAjout).toLocaleDateString(
                        "fr-FR",
                        {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          // hour: '2-digit',
                          // minute: '2-digit',
                          // second: '2-digit',
                          hour12: false,
                        }
                      )
                      // (visionnage.dateVisionnage).
                    }</td>
                  <td>{parrainage.usagerParrain.nom}</td>
                  {/* <td>{retrait.moyenPaiement}</td> */}
                  {/* <td>{retrait.idOperateur.nomOperateur}</td> */}
                
                  <td>
                    {" "}
                    <i
                      onClick={() => handleUser(parrainage)}
                      className="fa-solid fa-tv"
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {displayItem && (
            <Details
              id={user._id}
              nomFilleuil={user.usagerFilleuil.nom}
              nomParrain={user.usagerParrain.nom}
              telFilleuil={user.usagerFilleuil.tel}
              telParrain={user.usagerParrain.tel}
              emailFilleuil={user.usagerFilleuil.email}
              emailParrain={user.usagerParrain.email}
              dateAjout={user.dateAjout}
              onClose={closeChild}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sect2;
