import React, {useState} from 'react'
import { Navigate } from 'react-router-dom'
// import useLocalState from "@phntms/use-local-state";
import { Cookies, useCookies } from "react-cookie";



const LoginRedirect = ({children}) => {

  const [cookies] = useCookies(['giga_admin']);

  const hasAdmin = cookies.giga_admin !== undefined;

    
    // const [admin_jwt, setJwtToken] = useState(null);


    const isTokenValid = () => {
        if (!hasAdmin) return false;
        
        // setJwtToken(cookies.admin.accessToken);

        const payload = cookies.giga_admin.accessToken.split(".")[1];
        const payloadData = JSON.parse(atob(payload));
        // console.log(payloadData);

        const currentTime = new Date().getTime() / 1000;
        return currentTime < payloadData.exp;
        // console.log(currentTime);
      };


    return  isTokenValid() ? <Navigate to="/home" /> : children
}

export default LoginRedirect