import React, {useState, useEffect, useContext} from 'react'
import banner_bg from "./images/home_banner.png"
import axios from 'axios'
import {ScaleLoader} from "react-spinners";
import { RegContext } from "../../regContext/RegContext";

const { BACKEND_API_URL } = require("../../../../env");




const Bilan = (props) => {
  return (
    <div className='sect1_bilan' >

      <div>{props.count}</div>
      <span>{props.title}</span>
        
    </div>
  )
}

const Sect1 = () => {

  const [nombrePartenaire, setNombrePartenaire] = useState(0);
  const [nomrebHommeSandwich, setNombreHommeSandwich] = useState(0);
  const [nombreVisionnage, setNombreVisionnage] = useState(0);
  const [nombreUsagers, setNombreUsagers] = useState(0);

  const [visionnagesParM, setVisionnagesParM] = useState(0)
  const [visionnagesParF, setVisionnagesParF] = useState(0)

  const {
    setTotalPartenaires,
    totalPartenaires,
    totalUsagers,
    setTotalUsagers,
    totalParrainages,
    setTotalParrainages,
  } = React.useContext(RegContext);

  useEffect(() => {


    Promise.all([
      axios.get(`${BACKEND_API_URL}/api/getCountPartenaire`),
      axios.get(`${BACKEND_API_URL}/api/visionnage/getTotal`),
      axios.get(`${BACKEND_API_URL}/api/usager/getCountUsager`),
      axios.get(`${BACKEND_API_URL}/api/parrainage/number`),
      // /api/parrainage
  
    ])
    .then(([dataPartenaire,  dataVisionnage, dataCountUsager, dataCountParrainage]) => {



      const partenaires = dataPartenaire.data.count;
      const count = partenaires.length;
      setNombrePartenaire(partenaires);

      setTotalPartenaires(partenaires);

      const visionnages = dataVisionnage.data.total;


      setNombreVisionnage(visionnages);
      
      const countUsager = dataCountUsager.data;
      setNombreUsagers(countUsager.countUsager);

      setTotalUsagers(countUsager.countUsager);



      setVisionnagesParM(countUsager.countMal);
      setVisionnagesParF(countUsager.countFemelle);

      // console.log("parrainage count", dataCountParrainage.data)

      setTotalParrainages(dataCountParrainage.data);


    })
    .catch((error) => {});

    // axios
    // .get("http://localhost:3005/api/partenaires")
    // .then((response) => {
    //   const partenaires = response.data;
    //   const count = partenaires.length;
    //   setNombrePartenaire(count);
     
    // })
    // .catch((error) => {
    // });

  }, [])

  return (
    <div className='sect1' >

      <div>
            <Bilan title="Total Visionnages" count={nombreVisionnage} />
            <Bilan title="Partenaires inscrits" count={nombrePartenaire} />
            {/* <Bilan title="Gigas distribués" count="NaN" /> */}
            <Bilan title="Usagers" count={nombreUsagers} />
            {/* <Bilan title="Total partages" count="3" /> */}

            <Bilan title="Parrainages" count={totalParrainages} />
            <Bilan title="Partages" count={0} />
            {/* <Bilan title="Demandes H-Sandwich" count={nomrebHommeSandwich} /> */}
      </div>
      <div style={{ backgroundImage: `url(${banner_bg})` }} >

      </div>
        
    </div>
  )
}

export default Sect1