import React from 'react'
import Sect1 from './sub/Utilisateur/Sect1'
import Sect2 from "./sub/Utilisateur/Sect2"
export default function Utilisateurs() {
  return (
    <div>
      <Sect1 />
       {/* <Sect2 /> */}
    </div>
  )
}
