import React, {useState} from 'react'
import { Navigate } from 'react-router-dom'
import { Cookies, useCookies } from "react-cookie";


const PrivateRoute = ({children}) => {


const [cookies] = useCookies(['giga_admin']);

const hasAdmin = cookies.giga_admin !== undefined;

// const [giga_admin_jwtToken, setJwtToken] = useState(cookies.get('giga_admin_jwtToken').accessToken);


const isTokenValid = () => {
    if (!hasAdmin) return false;

    // setJwtToken(cookies.admin.accessToken);
    const payload = cookies.giga_admin.accessToken.split(".")[1];
    const payloadData = JSON.parse(atob(payload));
    // console.log(payloadData);

    const currentTime = new Date().getTime() / 1000;
    return currentTime < payloadData.exp;
    // console.log(currentTime);
  };


return  isTokenValid() ? children : <Navigate to="/" />

}

export default PrivateRoute

// const PrivateRoute = ({children}) => {

//     const [user_jwt, setUser_jwt] = useLocalState("", "user_jwt")
//     const [giga_admin_jwtToken, setJwtToken] = useState(localStorage.getItem("giga_admin_jwtToken"));


//     const isTokenValid = () => {
//         if (!giga_admin_jwtToken) return false;
    
//         const payload = giga_admin_jwtToken.split(".")[1];
//         const payloadData = JSON.parse(atob(payload));
//         console.log(payloadData);

//         const currentTime = new Date().getTime() / 1000;
//         return currentTime < payloadData.exp;
//         // console.log(currentTime);
//       };


//     return  isTokenValid() ? children : <Navigate to="/" />
// }

// export default PrivateRoute