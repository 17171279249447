import React from 'react'
import Sect1 from './sub/donnees/Sect1'
import Sect2 from './sub/donnees/Sect2'

const Donnees = () => {
  return (
    <section className='partenaires' >

    <Sect1 />
    <Sect2 />

   </section>
  )
}

export default Donnees