import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { toastPlacements } from "rsuite/esm/toaster/ToastContainer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { RegContext } from "../../regContext/RegContext";
import { ScaleLoader } from "react-spinners";

const { BACKEND_API_URL } = require("../../../../env");

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Details = (props) => {
  // const [displayItem, setDisplayItem] = useState(true)
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    // setOpen(false);
    setRetraitError(false);
  };

  const { retraitStatus, setRetraitStatus,  retraitflag1,
    setRetraitflag1, } = useContext(RegContext);
  const [refTerm, setRefTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [retraitError, setRetraitError] = useState(false);
  // const [open, setOpen] = useState(true);
  //

  const handleUpdateRetrait = async () => {
    setLoading(true);

    try {
      const response = await axios.put(
        `${BACKEND_API_URL}/api/retrait/${props.id}`,
        {
          refPaiment: refTerm,
          email: props.email,
        }
      );

      console.log(response.data); // Handle the response as needed
      setRetraitStatus(true);
      setRetraitError(true);
      setRetraitflag1(true);
      // Optionally, you can add a success message or update the UI accordingly
    } catch (error) {
      console.error("There was an error updating the retrait!", error);
    } finally {
      setLoading(false);
    }
  };

  const date = new Date(props.dateDemande); // Crée une instance de l'objet Date à partir de la prop 'date'
  const date1 = new Date(props.datePaiement); // Crée une instance de l'objet Date à partir de la prop 'date'
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  const formattedDate1 = `${date.toLocaleDateString("fr-FR", options)}`;
  const formattedDate2 = `${date1.toLocaleDateString("fr-FR", options)}`;
  return (
    <div className="sect3_elem_details">
      <div>
        {/* <FontAwesomeIcon  style={{color:"black"}}  icon="fa-solid fa-circle-xmark" /> */}
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
          <Stack spacing={2} sx={{ width: "70%" }}>
            <Snackbar
              open={retraitError}
              autoHideDuration={2000}
              onClose={handleClose}
            >
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Retrait confirmé avec succès, un email de confirmation a été
                envoyé à l'utilisateur
              </Alert>
            </Snackbar>
          </Stack>
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>{/* <b> </b>{" "} */}</span>
              {/* <span> {props.partDescription} </span> */}
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.id} </span>{" "}
              </div>
              <div>
                <span> Nom Utilisateur : &nbsp; </span>{" "}
                <span>
                  {props.email} &nbsp; <b>S</b>{" "}
                </span>
              </div>
              <div>
                <span> Numero Telephone : &nbsp; </span>{" "}
                <span> {props.numPaiement} </span>{" "}
              </div>
              <div>
                <span> Points dus: &nbsp; </span>{" "}
                <span
                  style={{
                    backgroundColor: "#ac180431",
                    color: "#ac1804",
                  }}
                >
                  {" "}
                  {props.pointRetrait}{" "}
                </span>{" "}
              </div>
              <div>
                <span> Moyen de paiment : &nbsp; </span>{" "}
                <span> {props.moyenPaiement} </span>{" "}
              </div>
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> </b>{" "}
              </span>
              {/* <span> {props.partDescription} </span> */}
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>

              <div>
                <span> Date demande : &nbsp; </span>{" "}
                <span>{formattedDate1} &nbsp;</span>
              </div>

              {props.datePaiement === null ? (
                <>
                  {retraitflag1 ==false ? (
                    <>
                      <div>
                        <input
                          type="text"
                          placeholder="Entrer la reference de paiement"
                          className="search-bar-hs2"
                          style={{
                            width: "29vw",
                          }}
                          value={refTerm}
                          onChange={(e) => setRefTerm(e.target.value)}
                        />
                      </div>
                      <div>
                        <button
                          style={{
                            position: "relative",
                            left: "1px",
                            fontSize: "12px",
                            color: "#4eac04",
                          }}
                          onClick={handleUpdateRetrait}
                          disabled={loading}
                          className="search-button2"
                        >
                          {loading ? "Loading..." : "Confirmer"}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          backgroundColor: "gold",
                        }}
                      >
                        <span>
                          <b>Cette demande vient d'être traitée </b>
                        </span>
                      </div>
                    </>
                  )
                  }
                </>
              ) : (
                <>
                  <div>
                    <span
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      Date de confirmation : &nbsp;{" "}
                    </span>{" "}
                    <span>{formattedDate2}</span>
                  </div>

                  <div>
                    <span
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      Reference {props.moyenPaiement}: &nbsp;{" "}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      {props.refPaiment}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Bilan = (props) => {
  return (
    <div className="sect1_bilan">
      <div style={{ borderColor: props.color }}>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};
const Sect1 = () => {
  const [nomOperateur, setNomOperateur] = useState([]);
  const [info, setInfo] = useState([]);
  const [displayItem, setDisplayItem] = useState(false);
  const [user, setUser] = useState([]);

  const {
    retraitStatus,
    setRetraitStatus,
    setTotalPartenaires,
    totalPartenaires,
    totalUsagers,
    setTotalUsagers,
    totalParrainages,
    setTotalParrainages,
    retraitflag1,
    setRetraitflag1,
  } = useContext(RegContext);

  const [total, setTotal] = useState(0);
  const [itemsPerPage2, setItemsPerPage2] = useState(8);
  const [searchTerm2, setSearchTerm2] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(0);
  const [hommesSandwich, setHommesSandwich] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [visionnages, setVisionnages] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [retraits, setRetraits] = useState([]);
  const [countDemandes, setCountDemandes] = useState(0);
  // const [retaitStatus, setRetraitStatus] = useState(false);
  const [retaitStatus, setRetaitStatus] = useState(false);

  const closeChild = () => {
    setDisplayItem(false);
    setRetraitflag1(false);
  };
  useEffect(() => {
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = currentPage2;
    const statusParam = retaitStatus ? "true" : "false"; // Determine status based on retaitStatus

    axios
      .get(
        `${BACKEND_API_URL}/api/retrait?limit=${limit1}&page=${page}&search=${searchTerm2}&status=${statusParam}`
      )
      .then((response) => {
        setRetraits(response.data.retraits);
        setCountDemandes(response.data.countFalse);
        setTotalPages2(response.data.totalPages);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage2, searchTerm2, retaitStatus, retraitStatus]);

  const paginate2 = (pageNumber) => {
    setCurrentPage2(pageNumber);
    setLoading(true);
    const limit1 = itemsPerPage2;
    const page = pageNumber;
    const statusParam = retaitStatus ? "true" : "false"; // Determine status based on retaitStatus

    axios
      .get(
        `${BACKEND_API_URL}/api/retrait?limit=${limit1}&page=${page}&status=${statusParam}`
      )
      .then((response) => {
        setRetraits(response.data.retraits);
        setCountDemandes(response.data.countFalse);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRefresh = () => {
    setRetaitStatus(false); // Set status to false to refresh data
  };

  const handleHistory = () => {
    setRetaitStatus(true); // Set status to true to fetch historical data
  };

  const handleUser = (info) => {
    setUser(info);
    setDisplayItem(true);
  };

  // alert(totalPartenaires)
  return (
    <div className="partenaires_sect1 points_sect1">
      <div className="partenaires_sect1_top">
        <div
          className="donnees_sect1_bilan_left"
          style={{
            fontSize: "12px",
          }}
        >
          {" "}
          Gestion points & parrainages{" "}
        </div>
        {/* <div className='donnees_sect1_bilan_right' > Filtrer &nbsp;  <i class="fa-solid fa-sort"></i></div> */}
        {/* 
      <Bilan  title="Orange" count="210" color="#FF7900" />
      <Bilan title="Moov" count="120" color="#0066B3" />
      <Bilan title="Mtn" count="170" color="#FFCA00" />
           */}
        <Bilan title="Retraits en attente" count={countDemandes} />
        <Bilan title="Parrainages" count={totalParrainages} />
        <Bilan title="Partenaires" count={17} />
        <Bilan title="Usagers" count={totalUsagers} />
        <Bilan title="Totales partages" count={0} />

        {/* {nomOperateur.map(nomOperateur =>(
        <Bilan key={nomOperateur._id} title={nomOperateur.nom} count={nomOperateur.nbre} color={nomOperateur.nom == "Moov" ? "#E2EAED" : nomOperateur.nom == "Orange" ? "#E2EAED": "#E2EAED" } />
      ))} */}
      </div>

      <div className="partenaires_sect1_bottom">
        <div className="partenaires_sect1_bottom_sect_topPoints partenaires_sect1_bottom_sect_topPoints3">
          <span className="blink">({countDemandes}) nouvelles</span>
          <div>Demandes de retrait</div>
          <div className="search-bar-container2">
            <input
              type="text"
              placeholder="Recherche par email"
              className="search-bar-hs2"
              value={searchTerm2}
              onChange={(e) => setSearchTerm2(e.target.value)}
            />
            <button
              onClick=""
              disabled={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? true
                  : false
              }
              className={
                (loading && searchTerm2.length > 0) ||
                (!loading && searchTerm2.length == 0)
                  ? "search-button-disabled2"
                  : "search-button2"
              }
            >
              <i className="fa fa-search"></i>
            </button>

            <button
              style={{
                position: "absolute",
                right: "1px",
                top: "7vh",
                fontSize: "12px",
                color: "#4eac04",
                width: "5vw",
                height: "3vh",
              }}
              onClick={handleRefresh}
              className="search-button2"
              disabled={loading}
            >
              <i className="fa fa-sync"></i> Actualiser
            </button>
            <button
              style={{
                position: "absolute",
                left: "5.5vw",
                top: "7vh",
                fontSize: "12px",
                color: "tomato",
                width: "5vw",
                height: "3vh",
              }}
              onClick={handleHistory}
              className="search-button2"
              disabled={loading}
            >
              <i className="fa fa-history"></i> Historique
            </button>
          </div>
        </div>

        <div
          className="partenaires_sect1_bottom_sect_table partenaires_sect1_bottom_sect_table3 "
          //  style={{ overflowY: 'scroll', height: '300px' }}
        >
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Email</th>
                <th>Points</th>
                <th>Numéro de paiment</th>
                <th>Moyen de paiment</th>
                <th> Action </th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <ScaleLoader
                  style={{
                    position: "absolute",
                    top: "20vh",
                    left: "48%",
                  }}
                  color="#4EAC04"
                />
              )}
              {retraits.map((retrait) => (
                <tr key={retrait._id}>
                  <td>{retrait._id}</td>
                  <td>{retrait.email}</td>
                  <td
                    style={{
                      backgroundColor:
                        retrait.datePaiement == null ? "" : "red",
                    }}
                  >
                    {retrait.datePaiement == null ? "" : "-"}{" "}
                    {retrait.pointRetrait}
                  </td>

                  <td>{retrait.numPaiement}</td>
                  <td>{retrait.moyenPaiement}</td>
                  {/* <td>{retrait.idOperateur.nomOperateur}</td> */}

                  {/* <td>1</td>
              <td>58</td>
              <td>88</td>
              <td>186</td>
              <td>05</td>
              <td>05</td> */}
                  <td>
                    {" "}
                    <i
                      onClick={() => handleUser(retrait)}
                      className="fa-solid fa-tv"
                    ></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination-hs">
            <button
              className="pagination-button"
              onClick={() => paginate2(currentPage2 - 1)}
              disabled={currentPage2 === 1}
            >
              <i className="fa fa-angle-left"></i>
            </button>
            <span className="current-page">
              {currentPage2}/{totalPages2}
            </span>
            <button
              className="pagination-button"
              onClick={() => paginate2(currentPage2 + 1)}
              disabled={currentPage2 === totalPages2}
            >
              <i className="fa fa-angle-right"></i>
            </button>
          </div>

          {displayItem && (
            <Details
              id={user._id}
              email={user.email}
              pointRetrait={user.pointRetrait}
              numPaiement={user.numPaiement}
              moyenPaiement={user.moyenPaiement}
              dateDemande={user.dateAjout}
              datePaiement={user.datePaiement}
              refPaiment={user.refPaiment}
              //  video ={user.idVideo.intitulee}
              //  tel={user.idUsager.tel}
              //  gigaDonnée={user.nbreDonneeCreditee}
              //  datenaisse={user.idUsager.datenaissance}
              //  operateur={user.idOperateur.nomOperateur}

              onClose={closeChild}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sect1;
