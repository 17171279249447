import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { UserContext } from "../context/UserContext";
import contact from "../../images/perm-contact-calendar.svg";
import userP from "../../images/user-circle.svg";
import { ScaleLoader } from "react-spinners";
import "../../../../styles/nav.css";
const { BACKEND_API_URL } = require("../../../../env");

const Bilan = (props) => {
  return (
    <div className="sect1_bilan  ">
      <div>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};
const Bilan1 = (props) => {
  return (
    <div className="sect1_bilan sect1_bilanU1  ">
      <div>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};
const Bilan2 = (props) => {
  return (
    <div className="sect1_bilan sect1_bilanU2  ">
      <div>{props.count}</div>
      <span>{props.title}</span>
    </div>
  );
};

const Details = (props) => {
  // const [displayItem, setDisplayItem] = useState(true)
  const [statut, setStatut] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // axios.get('http://localhost:3005/api/usager/foundUsager')
    // .then((res)=>{
    //   console.log(res.data)
    //   setStatut(res.data)
    // }).catch((e)=>console.log(e))
  }, []);
  const blockUser = (id) => {
    axios
      .put(`${BACKEND_API_URL}/api/usager/usagers/${id}/block`)
      .then((res) => {
        console.log(res);
        setLoading(false);

        window.location.reload();
      })
      .catch((e) => console.log(e));
    console.log("ok block");
  };
  const unBlockUser = (id) => {
    axios
      .put(`${BACKEND_API_URL}/api/usager/usagers/${id}/unblock`)
      .then((res) => {
        console.log(res);
        window.location.reload();
        setLoading(false);
      })
      .catch((e) => console.log(e));
    console.log("ok unblock");
  };
  const handleBlockClick = (id) => {
    blockUser(id);
    setLoading(true);
  };
  const Delete = (id) => {
    axios
      .delete(`${BACKEND_API_URL}/api/usager/usagers/${id}`)
      .then((res) => {
        console.log(res);
        window.location.reload();
    setLoading(false)

      })
      .catch((e) => console.log(e));
  };
  const handleDelete = (id) => {
    Delete(id);
    setLoading(true)

  };
  const handleUnBlockClick = (id) => {
    unBlockUser(id);
    setLoading(true);

    // window.location.reload();
  };
  return (
    <div className="sect3_elem_details">
      <div>
        {/* <FontAwesomeIcon  style={{color:"black"}}  icon="fa-solid fa-circle-xmark" /> */}
        <div onClick={props.onClose} className="elem_details_closure">
          {" "}
          <i className="fa-solid fa-xmark"></i>{" "}
        </div>

        <div className="part_details_div">
          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Utilisateur </b>{" "}
              </span>
              {/* <span> {props.partDescription} </span> */}
            </div>

            <div className="row1_details_details">
              <br />

              <span style={{ color: "black" }}>
                {" "}
                <b>Détails Utilisateur</b>
              </span>

              <div>
                <span> ID : &nbsp; </span> <span> {props.id} </span>{" "}
              </div>
              <div>
                <span> Nom Utilisateur : &nbsp; </span>{" "}
                <span>
                  {" "}
                  {props.nom} &nbsp; <b>S</b>{" "}
                </span>
              </div>
              <div>
                <span> Numero Telephone : &nbsp; </span>{" "}
                <span> {props.tel} </span>{" "}
              </div>
              <div>
                <span> Localisation : &nbsp; </span>{" "}
                <span> {props.localisation} </span>{" "}
              </div>
              <div>
                <span> Sexe : &nbsp; </span> <span> {props.sexe} </span>{" "}
              </div>
              <div>
                <span> Date Naissance : &nbsp; </span>{" "}
                <span> {props.datenaisse} </span>{" "}
              </div>
            </div>
          </div>

          <div>
            <div className="row1_details_header">
              <span style={{ color: "black" }}>
                <b> Détails Visionnage </b>{" "}
              </span>
              {/* <span> {props.partDescription} </span> */}
            </div>

            <div className="row1_details_details">
              <br />
              <span style={{ color: "black" }}>
                {" "}
                <b>Détails</b>
              </span>
              <div>
                <span> Operateur : &nbsp; </span>{" "}
                <span> {props.operateur} </span>{" "}
              </div>
              <div>
                <span> Statut : &nbsp; </span> <span> {props.statut} </span>{" "}
              </div>
              <div>
                <span> Nombre Point : &nbsp; </span>{" "}
                <span> {props.point} </span>{" "}
              </div>

              <div className="row_status_div">
                <span>Choisir le statut:</span>
                <button
                  style={{
                    width: "80px",
                    height: "25px",
                    borderRadius: 7,
                    border: "none",
                    backgroundColor: "red",
                    fontWeight: "bold",
                    color: "#ffff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleBlockClick(props.id)}
                >
                  Bloquer
                </button>
                <button
                  style={{
                    width: "90px",
                    height: "25px",
                    borderRadius: 7,
                    border: "none",
                    backgroundColor: "green",
                    fontWeight: "bold",
                    color: "#ffff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleUnBlockClick(props.id)}
                >
                  Debloquer
                </button>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  style={{
                    width: "100px",
                    height: "25px",
                    borderRadius: 7,
                    border: "none",
                    backgroundColor: "red",
                    fontWeight: "bold",
                    color: "#ffff",
                    cursor: "pointer",
                  }}
                  onClick={() => handleDelete(props.id)}
                >
                  Supprimer
                </button>
              </div>
              {loading && (
                <ScaleLoader
                  style={{
                    position: "absolute",
                    top: "45vh",
                    left: "48%",
                  }}
                  color="#4EAC04"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Sect1 = (props) => {
  const [nom, setNom] = useState("");
  const [sexe, setSexe] = useState("");
  const [tel, setTel] = useState("");
  const [localisation, setLocalisation] = useState("");
  const [info, setInfo] = useState([]);
  const [count, setCount] = useState([]);
  const [user, setUser] = useState([]);
  const [displayItem, setDisplayItem] = useState(false);
  const [statut, setStatut] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  // const [limit, setLimit] = useState(8);
  // const { name, age, gender } = useContext(UserContext);
  const [users, setUsers] = useState([]);

  const closeChild = () => {
    setDisplayItem(false);
  };
  console.log(statut);
  useEffect(() => {
    // axios.get("http://localhost:3005/api/usager/getUsager")
    // .then((response) => {
    //   console.log(response.data);
    //   setInfo(response.data.usager)
    // })
    // .catch((error) => {
    //   console.log(error);
    // });

    axios
      .get(
        `${BACKEND_API_URL}/api/usager/foundUsager?nom=${nom}&sexe=${sexe}&localisation=${localisation}&tel=${tel}&statut=${statut}&page=${page}&limit=8`
      )
      .then((response) => {
        console.log(response.data);
        setInfo(response.data.usagers);
        setLoading(false);
        setTotalPages(response.data.totalPages); // Assurez-vous que votre API retourne ce champ
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("ok");

    axios
      .get(`${BACKEND_API_URL}/api/usager/getCountUsager`)
      .then((response) => {
        setCount(response.data);
        // setInfo(response.data.transaction)
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [nom, sexe, localisation, tel, statut, page]);
  const handleUser = (info) => {
    setUser(info);
    setDisplayItem(true);
  };
  // const searchUser = async () => {
  //   axios
  //     .get(
  //       `${BACKEND_API_URL}/api/usager/foundUsager?nom=${nom}&sexe=${sexe}&localisation=${localisation}&tel=${tel}`
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //       setUsers(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   console.log("ok");
  // };
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setLoading(true);

      setPage(newPage);
    }
  };

  return (
    <div className="geneUser">
      <div className="partenaires_sect1">
        <div className="partenaires_sect1_top">
          <Bilan title="Utilisateurs total" count={count.countUsager} />
          <Bilan1 title="Masculin " count={count.countMal} />
          <Bilan2 title="Feminin " count={count.countFemelle} />
        </div>

        <div className="partenaires_sect1_bottom">
          <div className="partenaires_sect1_bottom_sect_top">
            <div></div>
            <div>Liste Utilisateur</div>
          </div>

          <div
            className="partenaires_sect1_bottom_sect_table"
            style={{ overflowY: "scroll", height: "300px" }}
          >
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nom</th>
                  <th>Sexe</th>
                  <th>Numero</th>
                  <th>Commune</th>
                  <th>Statut</th>
                  <th> Action </th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <ScaleLoader
                    style={{
                      position: "absolute",
                      top: "20vh",
                      left: "48%",
                    }}
                    color="#4EAC04"
                  />
                )}
                {info.map((info) => (
                  <tr key={info._id}>
                    <td>{info._id}</td>
                    <td>{info.nom}</td>
                    <td>{info.sexe}</td>
                    <td>{info.tel}</td>
                    <td>{info.localisation}</td>
                    <td st>{info.statut ? "Debloquer" : "Bloquer"}</td>
                    <td>
                      {" "}
                      <i
                        onClick={() => handleUser(info)}
                        className="fa-solid fa-tv"
                      ></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              <button
                className="pagination-button"
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1}
              >
                <i className="fa fa-angle-left"></i>
              </button>
              <span>
                {page}/{totalPages}
              </span>
              <button
                className="pagination-button"
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages}
              >
                <i className="fa fa-angle-right"></i>
              </button>
            </div>

            {displayItem && (
              <Details
                id={user._id}
                nom={user.nom}
                sexe={user.sexe}
                tel={user.tel}
                localisation={user.localisation}
                datenaisse={user.datenaissance}
                operateur={user.operateur}
                statut={user.statut ? "Debloquer" : "Bloquer"}
                point={user.points}
                onClose={closeChild}
              />
            )}
          </div>
        </div>
      </div>
      <div className="user_sect2">
        <div className="user_sect2_part_top">
          <div> Profil utilisateur selectionné </div>
        </div>

        <div className="user_sect2_part_bottom">
          <div className="user_add_form">
            <div>
              <div className="icon-user">
                {" "}
                <img src={userP} />
              </div>

              <div className="user_form_field">
                <input
                  id="nom"
                  type="text"
                  placeholder="Nom complet"
                  name=""
                  onChange={(e) => setNom(e.target.value)}
                />
              </div>
              <div className="user_form_field">
                <input
                  id="numero"
                  type="text"
                  placeholder="Numero"
                  name=""
                  onChange={(e) => setTel(e.target.value)}
                />
              </div>

              <div className="user_form_field">
                <input
                  id="commune"
                  type="text"
                  placeholder="Commune"
                  name=""
                  onChange={(e) => setLocalisation(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className='box-butts'> */}
        {/* <button className='but-drop' onClick={()=>searchUser()}> Valider </button>
<button className='but-drops'> Supprimer </button> */}
        {/* </div> */}

        <div className="box-filter">
          <div className="range-age">
            <div className="filter">
              <h4>Filtrer</h4>
              <img src={contact} width="22px" />
            </div>
            {/* <label>Age <span>(Entre 18 et 60)</span></label>
  <input type="range" min="10" max="80"/> */}
            <label>Sexe</label>
          </div>
          <div className="but-sexe">
            <button
              value="masculin"
              onClick={(e) => setSexe(e.target.value)}
              style={{ cursor: "pointer" }}
            >
              Masculin
            </button>
            <button
              value="féminin"
              onClick={(e) => setSexe(e.target.value)}
              style={{ cursor: "pointer" }}
            >
              Feminin
            </button>
          </div>
          <label
            style={{
              fontWeight: "bold",
              color: "#ffff",
              position: "relative",
              right: 80,
              marginTop: 10,
            }}
          >
            Statut Utilisateur
          </label>

          <div className="but-sexe" style={{ marginTop: 20 }}>
            <button
              onClick={() => setStatut(false)}
              style={{ cursor: "pointer" }}
            >
              Bloquer
            </button>
            <button
              onClick={() => setStatut(true)}
              style={{ cursor: "pointer", marginRight: -28 }}
            >
              Non Bloquer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sect1;
